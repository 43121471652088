import React, { useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import {
  Radio,
  RadioGroup,
  Box,
  FormControl,
  FormLabel,
  Textarea,
  CheckboxGroup,
  Checkbox,
  Input,
  Button,
  HStack,
  Text,
  Spinner,
  Heading,
  Flex,
} from "@chakra-ui/react";
import { BsCheck2Circle } from "react-icons/bs";
import { VscWarning } from "react-icons/vsc";

const schema = yup.object().shape({
  boardOrCommittee: yup.string().required("Board or commitee is required"),
  name: yup.string().required("Name is required"),
  residenceAddress: yup.string().required("Residence address is required"),
  mailingAddress: yup.string().required("Mailing address is required"),
  homePhone: yup
    .string()
    .required("Home phone is required")
    .matches(
      /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
      "Invalid phone number"
    ),
  currentEmployer: yup.string().required("Current employer/occupation is required"),
  // workPhone: yup
  //   .string()
  //   .required()
  //   .matches(
  //     /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,≈
  //     "Invalid phone number"
  //   ),
  email: yup
    .string()
    .required()
    .matches(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Invalid email"
    ),
  residentCityTetonSince: yup.date().required().typeError("Must be a date"),
  residentCityDriggsSince: yup.date().required().typeError("Must be a date"),
  resideDriggsAreaImpact: yup.string().required("Residence is required").nullable(),
  describeExperienceCommision: yup.string().required("Describe your experience is required"),
  consideredForAppointmentCommission: yup.string().required("Consideration is required"),
  anticipateConflictInterest: yup.string().required("Anticipate conflict is required"),
  experience_attachment: yup.mixed()
    .test("fileSize", "Max file size 1 MB", (value, context) => {
      if (!value || !value.length) {
        return true;
    } else {
    return value && value[0] && value[0].size <= 1000000;
    }
    }),
  why_be_considered_attachment: yup.mixed()
    .test('fileSize', 'Max file size 1 MB', (value, context) => {
    if (!value || !value.length) {
        return true;
    } else {
    return value && value[0] && value[0].size <= 1000000;
    }
  }),
  conflict_of_interest_attachment: yup.mixed()
    .test('fileSize', 'Max file size 1 MB', (value, context) => {
    if (!value || !value.length) {
        return true;
    } else {
    return value && value[0] && value[0].size <= 1000000;
    }
  }),
});

const errorStyles = {
  color: "#bf1650",
  fontSize: "1rem",
};



function RequiredSpan() {
  return <span style={{ color: "#bf1650" }}>*</span>;
}
const ComitteeBoardPositions = () => {
  const defaultValues = {
    licenseType: "",
    imgUrl: "",
  };

 

  const {
    register,
    handleSubmit,
    watch,
    control,
    reset,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });
  const [succeeded, setSucceeded] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState(null);
  const [disableSubmit, setDisableSubmit] = useState(true)

  const watchData = watch();
  const scrollTop = () => {
    window.scrollTo(0, 0);
  };
  const FormSuccess = () => {
    scrollTop();
    return (
      <Box my="20">
        <HStack>
          <BsCheck2Circle size="4rem" />
          <Heading as="h2" textAlign="left" my="4">
            Application Submitted Successfully!
          </Heading>
        </HStack>
  
        <Text my="4">
          Thank you for submitting your application. We will be in touch with
          you shortly.
        </Text>
        <Text my="4">
          Please check your email for a copy of your application for your
          records.
        </Text>
      </Box>
    );
  };
  


  // create a Ref to access our form element
  const formRef = useRef(null);

  const sendFormData = async (event) => {
    //event.preventDefault()
    setProcessing(true);

    if (!formRef.current) {
      console.log("something went wrong with the ref");
    }

    const formData = new FormData(formRef.current);

    // convert signature to blob
    //const applicantSignatureBlob = await (await fetch(watchData.applicantSignature)).blob([applicantSignatureBlob], "applicantSignature.png");

    // append blob to formData
    //formData.append("applicantSignature", applicantSignatureBlob, "applicantSignature.png");


    // Use console.log() to see the data that is being sent to the server
    //console.log(Array.from(formData.entries()));

      fetch('/.netlify/functions/send-committee-interest', {
           method: 'POST',
           body: formData,
       })
       // Convetring the response to JSON breaks status code
       .then(res => {
        console.log(res)
           if (res.status === 200) {
                 setSucceeded(true)
                 setProcessing(false)
                 reset()
                 //scrollTop()
             } else {
                 setSucceeded(false)
                 console.log("network error")
                 alert("Network error. Please try again later.")
             }
    }
 )
  };


  return (
      <Box id="theForm">
        {succeeded ? (
          <FormSuccess />
        ) : (
          <>
            <form onSubmit={handleSubmit(sendFormData)} ref={formRef}>
              <Box mb="8">
                <FormLabel>
                  Which Board or Committee are you interested?<RequiredSpan />
                </FormLabel>
                <Input
                  type="text"
                  {...register("boardOrCommittee")}
                  autoComplete="on"
                />
                <p style={errorStyles}>
                  {errors.boardOrCommittee && (
                    <HStack spacing="5px">
                      <VscWarning />{" "}
                      <Box>{errors.boardOrCommittee?.message}</Box>
                    </HStack>
                  )}
                </p>
              </Box>

              <Box mb="8">
                <FormLabel>Name<RequiredSpan /></FormLabel>
                <Input type="text" {...register("name")} autoComplete="on" />
                <p style={errorStyles}>
                  {errors.name && (
                    <HStack spacing="5px">
                      <VscWarning /> <Box>{errors.name?.message}</Box>
                    </HStack>
                  )}
                </p>
              </Box>

              <Box mb="8">
                <FormLabel>Residence Address:<RequiredSpan /></FormLabel>
                <Input
                  type="text"
                  {...register("residenceAddress")}
                  autoComplete="on"
                />
                <p style={errorStyles}>
                  {errors.residenceAddress && (
                    <HStack spacing="5px">
                      <VscWarning />{" "}
                      <Box>{errors.residenceAddress?.message}</Box>
                    </HStack>
                  )}
                </p>
              </Box>

              <Box mb="8">
                <FormLabel>Mailing Address:<RequiredSpan /></FormLabel>
                <Input
                  type="text"
                  {...register("mailingAddress")}
                  autoComplete="on"
                />
                <p style={errorStyles}>
                  {errors.mailingAddress && (
                    <HStack spacing="5px">
                      <VscWarning /> <Box>{errors.mailingAddress?.message}</Box>
                    </HStack>
                  )}
                </p>
              </Box>

              <Box mb="8">
                <FormLabel>Home Phone<RequiredSpan /></FormLabel>
                <Input
                  type="tel"
                  {...register("homePhone")}
                  autoComplete="on"
                />
                <p style={errorStyles}>
                  {errors.homePhone && (
                    <HStack spacing="5px">
                      <VscWarning /> <Box>{errors.homePhone?.message}</Box>
                    </HStack>
                  )}
                </p>
              </Box>

              <Box mb="8">
                <FormLabel>Work Phone</FormLabel>
                <Input
                  type="tel"
                  {...register("workPhone")}
                  autoComplete="on"
                />
                <p style={errorStyles}>
                  {errors.workPhone && (
                    <HStack spacing="5px">
                      <VscWarning /> <Box>{errors.workPhone?.message}</Box>
                    </HStack>
                  )}
                </p>
              </Box>

              <Box mb="8">
                <FormLabel>E-Mail<RequiredSpan /></FormLabel>
                <Input type="email" {...register("email")} autoComplete="on" />
                <p style={errorStyles}>
                  {errors.email && (
                    <HStack spacing="5px">
                      <VscWarning /> <Box>{errors.email?.message}</Box>
                    </HStack>
                  )}
                </p>
              </Box>

              <Box mb="8">
                <FormLabel>Current Employer / Ocupation</FormLabel>
                <Input
                  type="text"
                  {...register("currentEmployer")}
                  autoComplete="on"
                />
                <p style={errorStyles}>
                  {errors.currentEmployer && (
                    <HStack spacing="5px">
                      <VscWarning />{" "}
                      <Box>{errors.currentEmployer?.message}</Box>
                    </HStack>
                  )}
                </p>
              </Box>

              <Box mb="8">
                <FormLabel>Position</FormLabel>
                <Input
                  type="text"
                  {...register("position")}
                  autoComplete="on"
                />
                <p style={errorStyles}>
                  {errors.position && (
                    <HStack spacing="5px">
                      <VscWarning /> <Box>{errors.position?.message}</Box>
                    </HStack>
                  )}
                </p>
              </Box>

              <Box mb="8">
                <FormLabel>Resident of the City of Driggs since<RequiredSpan /></FormLabel>
                <Input
                  type="date"
                  {...register("residentCityDriggsSince")}
                  autoComplete="on"
                />
                <p style={errorStyles}>
                  {errors.residentCityDriggsSince && (
                    <HStack spacing="5px">
                      <VscWarning />{" "}
                      <Box>{errors.residentCityDriggsSince?.message}</Box>
                    </HStack>
                  )}
                </p>
              </Box>

              <Box mb="8">
                <FormLabel>Resident of Teton county since<RequiredSpan /></FormLabel>
                <Input
                  type="date"
                  {...register("residentCityTetonSince")}
                  autoComplete="on"
                />
                <p style={errorStyles}>
                  {errors.residentCityTetonSince && (
                    <HStack spacing="5px">
                      <VscWarning />{" "}
                      <Box>{errors.residentCityTetonSince?.message}</Box>
                    </HStack>
                  )}
                </p>
              </Box>

              <Box my="8">
                <FormControl>
                  <FormLabel>Reside in the Driggs area of impact?<RequiredSpan /></FormLabel>
                  <RadioGroup>
                    <HStack spacing="18px" align="left">
                      <Radio
                        value="Yes"
                        name="resideDriggsAreaImpact"
                        {...register("resideDriggsAreaImpact")}
                      >
                        Yes
                      </Radio>
                      <Radio
                        value="No"
                        name="resideDriggsAreaImpact"
                        {...register("resideDriggsAreaImpact")}
                      >
                        No
                      </Radio>
                    </HStack>
                  </RadioGroup>
                </FormControl>
                <p style={errorStyles}>
                {errors.resideDriggsAreaImpact && (
                    <HStack spacing="5px">
                      <VscWarning />{" "}
                      <Box>{errors.resideDriggsAreaImpact?.message}</Box>
                    </HStack>
                  )}
                  </p>
              </Box>

              <Flex w="100%">
                <Box mb="8" w="70%" mr="4">
                  <FormLabel>
                    DESCRIBE THE EXPERIENCE THAT YOU WOULD BRING TO THE COMMISSION
                    (attach additional sheet or resume if desired):
                  </FormLabel>
                  <Textarea
                    {...register("describeExperienceCommision")}
                    autoComplete="on"
                  ></Textarea>
                  <p style={errorStyles}>
                    {errors.describeExperienceCommision && (
                      <HStack spacing="5px">
                        <VscWarning />{" "}
                        <Box>{errors.describeExperienceCommision?.message}</Box>
                      </HStack>
                    )}
                  </p>
                </Box>
                <Box w="30%">
                  <FormControl>
                    <FormLabel>
                      Additional Document:<br /><small>Max 1 MB file size</small>
                    </FormLabel>
                    <input
                      type="file"
                      id="experience_attachment"
                      name="experience_attachment"
                      {...register("experience_attachment")}
                    />
                  </FormControl>
                  <p style={errorStyles}>
                    {errors.experience_attachment && (
                      <HStack spacing="5px">
                        <VscWarning />{" "}
                        <Box>{errors.experience_attachment?.message}</Box>
                      </HStack>
                    )}
                  </p>
                </Box>
              </Flex>

              <Flex w="100%" mb="8">
                <Box w="70%" mr="4">
                  <FormLabel>
                    WHY DO YOU WANT TO BE CONSIDERED FOR APPOINTMENT TO THE
                    COMMISSION? (attach additional sheet if necessary)
                  </FormLabel>
                  <Textarea
                    {...register("consideredForAppointmentCommission")}
                    autoComplete="on"
                  ></Textarea>
                  <p style={errorStyles}>
                    {errors.consideredForAppointmentCommission && (
                      <HStack spacing="5px">
                        <VscWarning />{" "}
                        <Box>
                          {errors.consideredForAppointmentCommission?.message}
                        </Box>
                      </HStack>
                    )}
                  </p>
                </Box>
                <Box w="30%">
                <FormControl>
                  <FormLabel>
                    Additional Document:<br /><small>Max 1 MB file size</small>
                  </FormLabel>
                  <input
                    type="file"
                    id="why_be_considered_attachment"
                    name="why_be_considered_attachment"
                    {...register("why_be_considered_attachment")}
                  />
                </FormControl>
                <p style={errorStyles}>
                    {errors.why_be_considered_attachment && (
                      <HStack spacing="5px">
                        <VscWarning />{" "}
                        <Box>{errors.why_be_considered_attachment?.message}</Box>
                      </HStack>
                    )}
                  </p>
              </Box>
              </Flex>

              <Flex w="100%" mb="8">
                <Box mr="4" w="70%">
                  <FormLabel>
                    DO YOU ANTICIPATE ANY CONFLICT OF INTEREST IN ANY FINDING
                    WHICH YOU MIGHT BE REQUIRED TO MAKE AS A MEMBER OF THIS BOARD?
                  </FormLabel>
                  <Textarea
                    {...register("anticipateConflictInterest")}
                    autoComplete="on"
                  ></Textarea>
                  <p style={errorStyles}>
                    {errors.anticipateConflictInterest && (
                      <HStack spacing="5px">
                        <VscWarning />{" "}
                        <Box>{errors.anticipateConflictInterest?.message}</Box>
                      </HStack>
                    )}
                  </p>
                </Box>
                <Box w="30%">
                  <FormControl>
                    <FormLabel>
                      Additional Document:<br /><small>Max 1 MB file size</small>
                    </FormLabel>
                    <input
                      type="file"
                      id="conflict_of_interest_attachment"
                      name="conflict_of_interest_attachment"
                      {...register("conflict_of_interest_attachment")}
                    />
                  </FormControl>
                  <p style={errorStyles}>
                    {errors.conflict_of_interest_attachment && (
                      <HStack spacing="5px">
                        <VscWarning />{" "}
                        <Box>{errors.conflict_of_interest_attachment?.message}</Box>
                      </HStack>
                    )}
                  </p>
                </Box>
              </Flex>

            <Box>
              <input
                type="hidden"
                value={new Date()}
                name="timestamp"
                {...register("timestamp", { required: true })}
              />
            </Box>

              <Box my="8">
                <Button type="submit" textAlign="left" disabled={processing}>
                  {processing ? (
                    <Spinner />
                  ) : succeeded ? (
                    "Submitted!"
                  ) : (
                    "Submit"
                  )}
                </Button>
              </Box>
            </form>
          </>
        )}
      </Box>
  );
};

export default ComitteeBoardPositions;
